<template>
  <div id="page_not_found">
    <section class="bg-cl-primary py35 px20">
      <div class="container">
        <div class="row between-sm">
          <div class="col-sm-12 col-md-5 hidden-xs">
            <img src="/assets/404_error_2.png" :alt="$t('We looked everywhere.')" width="100%">
          </div>
          <div class="col-sm-12 col-md-7 col-xl-5 align-right align-center-xs end-sm">
            <p class="big-text weight-700 mb40">
              {{ $t('We looked everywhere.') }}
            </p>
            <p class="h4">
              {{ $t('It looks like no such page exists.') }}
            </p>
            <div class="home-button mb40 flex end-sm center-xs">
              <button-full
                :link="{ name: 'home' }"
                class="uppercase h5 mw280"
              >
                {{ $t('Go to home page') }}
              </button-full>
            </div>
            <p class="h4">
              {{ $t('Search for products by category') }}
            </p>
            <div class="flex between-sm around-xs wrap mb40">
              <router-link
                :to="localizedRoute('/prasa')"
                class="uppercase h5 button-full no-underline m5"
              >
                {{ $t('Prasa') }}
              </router-link>
              <router-link
                :to="localizedRoute('/ksiazki')"
                class="uppercase h5 button-full no-underline m5"
              >
                {{ $t('Książki') }}
              </router-link>
              <router-link
                :to="localizedRoute('/kolekcje')"
                class="uppercase h5 button-full no-underline m5"
              >
                {{ $t('Kolekcje') }}
              </router-link>
            </div>
            <div class="h4">
              {{ $t('or visit page') }}
              <router-link
                class="uppercase cl-primary-orange orange-uderline"
                :to="localizedRoute($t('/i/kontakt'))"
                exact
              >
                {{ $t('Kontakt') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Logger } from '@vue-storefront/core/lib/logger'
import i18n from '@vue-storefront/i18n'
import ButtonFull from 'theme/components/theme/ButtonFull'
import { isServer } from '@vue-storefront/core/helpers'

export default {
  name: 'PageNotFound',
  async asyncData ({ store, route, context }) {
    Logger.log('Entering asyncData for PageNotFound ' + new Date())()
    if (context) {
      context.output.cacheTags.add(`page-not-found`)
      context.server.response.statusCode = 404
    }

    await store.dispatch('homepage/loadBestsellers')
  },
  beforeRouteEnter (to, from, next) {
    if (isServer) {
      next()
    } else {
      next(vm => {
        vm.$store.commit('ui/routeChanged', { to: to, from: from })
      })
    }
  },
  metaInfo () {
    return {
      title: this.$route.meta.title || i18n.t('404 Page Not Found'),
      meta: [
        {
          vmid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow'
        }
      ]
    }
  },
  components: {
    ButtonFull
  }
}
</script>

<style lang="scss" scoped>
  .big-text {
    font-size: 90px;
    @media (max-width: 1199px) {
      font-size: 80px;
    }
    @media (max-width: 767px) {
      font-size: 40px;
    }
  }
  .mw280 {
    max-width: 280px;
  }
  .button-full {
    padding: 12px 10px;
    min-width: 120px;
    background-color: #1396EC;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 50px;
    text-align: center;
  }
  .orange-uderline {
    text-decoration: underline;
    color: #1396EC;
  }
  .wrap {
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    .mobile-link {
      display: inline-flex;
      margin: 5px;
    }
    .align-center-xs {
      text-align: center;
    }
  }
</style>
